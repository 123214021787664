import React, { useEffect, useState } from "react";
import axios from "axios";
import excelImage from "../../themes/blue/images/xlsx.jpg";
import { useSelector } from "react-redux";
import languages from "../../languages/languages";
import translator from "../../utils/translator";

export default function ExcelUpload({ fetchBatchList }: any) {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [response, setResponse] = useState(null);

  const [msg, setMSG] = useState("");

  // Handle file selection
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
    setProgress(0); // Reset progress when a new file is selected
    setResponse(null); // Clear any previous response
  };
  const language = useSelector((state: any) => state.language);
  // Handle file upload
  const handleUpload = () => {
    if (!file) return;
    const formData = new FormData();
    formData.append("upload", file);

    axios
      .post("/api/transaction/transfer/batch/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          // Calculate upload percentage and update state
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          setProgress(percentCompleted);
        },
      })
      .then((res) => {
        setResponse(res.data);
        fetchBatchList();

        setMSG(translator("فایل با موفقیت آپلود شد.", language.language.short));
      })
      .catch((err) => {
        console.error("Upload error:", err);
        setMSG(translator("خطای دسترسی", language.language.short));
      });
  };

  const selectFile = () => {
    var q: any = document.querySelector(".excel-file");
    q.click();
  };

  useEffect(() => {
    handleUpload();
  }, [file]);

  return (
    <div>
      <div className="cursor-pointer text-center">
        <img
          src={excelImage}
          onClick={selectFile}
          alt="Select excel file"
          className="max-w-56 block mx-auto"
        />
        {/* Progress Bar */}
        <div className="max-w-56 mx-auto">
          <div
            style={{
              width: "100%",
              backgroundColor: "#f3f3f3",
              marginTop: "10px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: `${progress}%`,
                height: "20px",
                backgroundColor: "#14B8A6",
                textAlign: "center",
                color: "white",
                borderRadius: "5px",
              }}
            >
              {progress}%
            </div>
          </div>
        </div>

        <span onClick={selectFile}>
          {languages[language.language.short].pageTransfer.s10}
        </span>
      </div>
      <input
        type="file"
        className="excel-file hidden"
        onChange={handleFileChange}
      />

      <p className="mt-1 text-center text-sm">{msg}</p>
    </div>
  );
}
