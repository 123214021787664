import {
  FaArrowLeft,
  FaArrowRight,
  FaBars,
  FaLanguage,
  FaRegUser,
} from "react-icons/fa6";
import { FaSignOutAlt, FaTimes } from "react-icons/fa";
import { transform } from "../utils/persianTransform";

import { useDispatch, useSelector } from "react-redux";
import { boxStatus, changeLanguage } from "../redux/languageSlice";
import { iSetUser } from "../redux/userSlice";
import languages from "../languages/languages";

import logo from "../themes/blue/images/logo-text.png";

import { FaArrowRightArrowLeft, FaMinus, FaPlus } from "react-icons/fa6";

import axios from "axios";
import { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import { changeSideMenuState } from "../redux/sideMenuSlice";
import config from "../configs/config";
import clsx from "clsx";
import translator from "../utils/translator";

const ways: any = ["/home"];

export default function LayoutMain({ children }: any) {
  const location = useLocation();

  const dispatch = useDispatch();

  const openLanguagesBox = (e: any) => {
    e.stopPropagation();
    // e.currentTarget.nextElementSibling.classList.toggle("hidden");
    dispatch(boxStatus("open"));
  };

  const changeLanguageFunc = (e: any, lng: any) => {
    dispatch(boxStatus("hidden"));
    dispatch(
      changeLanguage({
        short: lng,
        rtl: !!languages[lng].rtl,
      })
    );
  };

  const language = useSelector((state: any) => state.language);
  const [user, setUser] = useState({
    mainAccount: {
      balance: "0",
    },
  });

  const fetchData = async () => {
    try {
      let userRes: any = await axios.get("/api/current/user");
      console.log(userRes.data);
      setUser(userRes.data);
      dispatch(iSetUser(userRes.data));
    } catch (err) {
      return navigate("/");
    }
  };

  const navigate = useNavigate();

  const pathes = [
    { path: "/transaction/charge", backPath: "/home", title: "شارژ" },
    { path: "/transaction/withdraw", backPath: "/home", title: "برداشت" },
    { path: "/transaction/transfer", backPath: "/home", title: "انتقال" },
    { path: "/contacts", backPath: "/home", title: "مخاطبین" },
    { path: "/bank-accounts", backPath: "/home", title: "حساب های بانکی" },
    { path: "/doc/add-doc", backPath: "/home", title: "آپلود مدارک" },
    { path: "/pincode/set", backPath: "/home", title: "پین کد" },
  ];

  const logout = async () => {
    try {
      await axios.get("/api/logout");
      navigate("/");
    } catch (error) {
      console.log("error layout main");
    }
  };

  useEffect(() => {
    (async function () {
      try {
        if (location.pathname !== ways[ways.length - 1])
          ways.push(location.pathname);
        fetchData();
      } catch (error) {
        return navigate("/");
      }
    })();
  }, [location]);

  const backLink = () => {
    const path = ways[ways.length - 2] ? ways[ways.length - 2] : "/home";
    ways.pop();
    return path;
  };

  const generatePathDetails = () => {
    const myPath = pathes.find((p: any) => {
      return location.pathname === p.path;
    });
    return myPath ? myPath : { path: "/", backPath: "/", title: "" };
  };

  return (
    <div
      className={language.language.rtl ? "rtl-direction relative" : "relative"}
    >
      <SideMenu />
      <div
        className={clsx(
          "flex items-center justify-between bg-teal-500 p-2 sticky z-10",
          {
            "top-8": config.oldUrl ? true : false,
          }
        )}
      >
        {location.pathname === "/home" ? (
          <FaBars
            onClick={() => {
              dispatch(changeSideMenuState("open"));
            }}
            className="text-2xl cursor-pointer text-white"
          />
        ) : (
          <Link to={generatePathDetails().backPath}>
            {language.language.rtl ? (
              <FaArrowRight className="text-white text-2xl" />
            ) : (
              <FaArrowLeft className="text-white text-2xl" />
            )}
          </Link>
        )}

        {location.pathname === "/home" ? (
          <img src={logo} alt="" className="max-w-32" />
        ) : (
          <span className="text-white text-xl">
            {translator(generatePathDetails().title, language.language.short)}
          </span>
        )}
        <div className="flex text-2xl  items-center text-white relative">
          <FaSignOutAlt className="m-1 cursor-pointer" onClick={logout} />

          <div className={language.languageBox === "open" ? "block" : "hidden"}>
            <div
              className={
                language.language.rtl
                  ? "absolute bg-gray-600 top-6 left-9 text-lg text-center flex flex-col p-2 rounded-lg "
                  : "absolute bg-gray-600 top-6 right-9 text-lg text-center flex flex-col p-2 rounded-lg "
              }
            >
              {Object.values(languages).map((lng: any) => (
                <span
                  key={lng["short"]}
                  className={
                    languages[language.language.short].short === lng["short"]
                      ? "block cursor-pointer hover:text-white  text-teal-400"
                      : "block cursor-pointer text-gray-200 hover:text-white"
                  }
                  onClick={(e) => changeLanguageFunc(e, lng["short"])}
                >
                  {lng["language"]}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      {children}
      <div className="clear-both"></div>
      <div className="fixed bottom-0 right-0 left-0">
        <div className="flex justify-center flex-col w-full bg-gray-100 max-w-2xl mx-auto relative">
          <div
            className={
              location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              ) === "home"
                ? "flex mx-auto justify-around gap-y-3 gap-x-16 p-2 W-full"
                : "hidden"
            }
          >
            <div className="text-center ">
              <Link to="/transaction/charge" className="block w-full">
                <div className="w-16 h-16 rounded-full bg-teal-500  flex items-center justify-center text-4xl">
                  <FaPlus className="text-white" />
                </div>
                <span className="block text-center mt-2 text-gray-500">
                  {languages[language.language.short].charge}
                </span>
              </Link>
            </div>
            <div className="text-center ">
              <Link to="/transaction/transfer" className="block">
                <div className="w-16 h-16 rounded-full bg-teal-500  flex items-center justify-center  text-4xl">
                  <FaArrowRightArrowLeft className="text-white" />
                </div>
                <span className="block text-center mt-2 text-gray-500">
                  {languages[language.language.short].transfer}
                </span>
              </Link>
            </div>
            <div className="text-center">
              <Link to="/transaction/withdraw" className="block">
                <div className="w-16 h-16 rounded-full bg-teal-500  flex items-center justify-center  text-4xl">
                  <FaMinus className="text-white" />
                </div>
                <span className="block text-center mt-2 text-gray-500">
                  {languages[language.language.short].withdraw}
                </span>
              </Link>
            </div>
          </div>
          <div className="text-center py-1 bg-slate-400 text-white">
            {languages[language.language.short].balance}:{" "}
            {language.language.rtl
              ? transform.convertMoney(user.mainAccount.balance, true)
              : transform.convertMoney(user.mainAccount.balance)}{" "}
            {
              languages[language.language.short].dynamicWords[
                config.mainAccountUnit
              ]
            }
          </div>
        </div>
      </div>
    </div>
  );
}
