import ReactDOM from "react-dom/client";
import "./themes/blue/styles/main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import config from "./configs/config";
import { boxStatus } from "./redux/languageSlice";
import languages from "./languages/languages";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <MainPage />
  </Provider>
);

function MainPage() {
  const dispatch = useDispatch();
  const openLanguagesBox = () => {
    dispatch(boxStatus("close"));
  };

  const language = useSelector((state: any) => state.language);

  return (
    <div className="w-full h-full" onClick={openLanguagesBox}>
      {config.oldUrl ? (
        <a
          href={config.oldUrl}
          className="w-full text-center bg-gray-200 fixed text-gray-600 z-50  leading-8 "
        >
          {languages[language.language.short].oldVersion}
        </a>
      ) : null}
      <div
        className={
          config.oldUrl
            ? "w-full max-w-2xl mx-auto relative top-8"
            : "w-full max-w-2xl mx-auto relative "
        }
      >
        <App />
      </div>
    </div>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
