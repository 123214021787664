import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { changeSideMenuState } from "../redux/sideMenuSlice";
import languages from "../languages/languages";
import { boxStatus, changeLanguage } from "../redux/languageSlice";
import axios from "axios";
import { FaLanguage } from "react-icons/fa6";
const duration = 150;

export default function SideMenu() {
  const dispatch = useDispatch();
  const { sideMenuState } = useSelector((state: any) => state.sideMenu);

  const language = useSelector((state: any) => state.language);

  const [user, setUser] = useState<any>({
    username: "",
    roles: [],
  });

  const fetchData = async () => {
    try {
      let userRes: any = await axios.get("/api/current/user");

      setUser(userRes.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const changeLanguageFunc = (e: any, lng: any) => {
    dispatch(boxStatus("hidden"));
    dispatch(
      changeLanguage({
        short: lng,
        rtl: !!languages[lng].rtl,
      })
    );
  };

  return (
    <div className={sideMenuState === "open" ? "w-full" : "hidden"}>
      <div
        className="fixed bottom-0 right-0 left-0 top-0 z-50 bg-gray-700/50"
        onClick={() => dispatch(changeSideMenuState("close"))}
      >
        <div
          className="flex justify-center flex-col w-full max-w-2xl mx-auto relative "
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={
              language.language.rtl
                ? "absolute top-0 right-0 bottom-0 w-11/12 max-w-80 bg-gray-100 h-screen"
                : "absolute top-0 left-0 bottom-0 w-11/12 max-w-80 bg-gray-100  h-screen"
            }
          >
            <div className="w-full bg-teal-600 h-8 flex items-center p-2 text-white">
              <FaTimes
                className="cursor-pointer"
                onClick={() => dispatch(changeSideMenuState("close"))}
              />
            </div>
            <ul
              className="relative"
              onClick={() => dispatch(changeSideMenuState("close"))}
            >
              <li
                className="border-b-slate-300"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  className="w-full p-2 grid grid-flow-col auto-cols-max auto-rows-max border-b border-b-slate-300 cursor-pointer items-center "
                  onClick={(e: any) => {
                    e.currentTarget.nextElementSibling.classList.toggle(
                      "hidden"
                    );
                  }}
                >
                  {/* <FaLanguage className="cursor-pointer" /> */}
                  <span className="cursor-pointer  grid items-center">
                    {languages[language.language.short].changeLanguage}
                  </span>
                </div>
                <div className="w-full hidden">
                  {Object.values(languages).map((lng: any) => (
                    <span
                      key={lng["short"]}
                      className={
                        languages[language.language.short].short ===
                        lng["short"]
                          ? "block cursor-pointer hover:text-teal-800  text-teal-700 p-1 px-4 border-b border-b-teal-100"
                          : "block cursor-pointer text-gray-800 hover:text-teal-700 p-1 px-4 border-b border-b-teal-100"
                      }
                      onClick={(e: any) => {
                        changeLanguageFunc(e, lng["short"]);
                        dispatch(changeSideMenuState("close"));
                        e.currentTarget.parentElement.classList.add("hidden");
                      }}
                    >
                      {lng["language"]}
                    </span>
                  ))}
                </div>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/home"
                >
                  {languages[language.language.short].menu.s1}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/contacts"
                >
                  {languages[language.language.short].menu.s2}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/bank-accounts"
                >
                  {languages[language.language.short].menu.s3}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/doc/add-doc"
                >
                  {languages[language.language.short].menu.s4}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/pincode/set"
                >
                  {languages[language.language.short].menu.s5}
                </Link>
              </li>
              <li
                className={
                  user?.roles?.includes("ALT_WITHDRAW_REPORTER")
                    ? "block"
                    : "hidden"
                }
              >
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/transaction/subaccount-withdrawals"
                >
                  {languages[language.language.short].menu.s6}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
