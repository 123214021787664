import { useEffect, useState } from "react";
import LayoutMain from "../LayoutMain";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import languages from "../../languages/languages";
import translator from "../../utils/translator";
import { FaInfoCircle } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import loadingIcon from "../../themes/blue/images/loading.gif";
import { transform } from "../../utils/persianTransform";

export default function BatchComponents() {
  const [transactions, setTransactions] = useState([]);
  const language = useSelector((state: any) => state.language);
  const [loading, setLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `/api/transaction/transfer/batch/detail?guid=${id}`
        );
        setLoading(false);
        setTransactions(res.data.data);
      } catch (err) {}
    })();
  }, []);

  const setColor = (err: string, trxCode: number) => {
    if (err) {
      return {
        status: translator(
          languages[language.language.short].pageTransfer.s21,
          language.language.short
        ),
        bg: { "bg-red-100  border border-solid border-red-300": true },
        bgFul: { "bg-red-300": true },
      };
      //err -> ناموفق قرمز
    }
    if (!err && trxCode) {
      // err null trxcode موفق
      return {
        status: translator(
          languages[language.language.short].pageTransfer.s22,
          language.language.short
        ),
        bg: { "bg-green-100  border border-solid border-green-300": true },
        bgFul: { "bg-green-300": true },
      };
    }

    if (err && trxCode) {
      // err por trxcode por مشکل بک. خطا در استعلام خاکستری

      return {
        status: translator(
          languages[language.language.short].pageTransfer.s23,
          language.language.short
        ),
        bg: { "bg-red-100  border border-solid border-red-300": true },
        bgFul: { "bg-red-300": true },
      };
    }
    if (!err && !trxCode) {
      // err, trx code null در انتظار پرداخت خاسکتری
      return {
        status: translator(
          languages[language.language.short].pageTransfer.s24,
          language.language.short
        ),
        bg: { "bg-gray-100  border border-solid border-gray-300": true },
        bgFul: { "bg-gray-300": true },
      };
    }
  };

  const showErrorDetails = (e: any) => {
    e.currentTarget.nextElementSibling.classList.toggle("hidden");
  };

  const renderResult = () => {
    if (transactions.length) {
      return (
        <div className="w-full">
          {transactions.map((t: any) => (
            <div
              className={clsx(
                "w-11/12 mx-auto mt-8  hover:shadow ",
                setColor(t.error, t.trxCode)?.bg
              )}
            >
              <div className="flex w-full justify-center">
                <div
                  className={clsx(
                    "w-1/12 flex justify-center items-center relative ",
                    setColor(t.error, t.trxCode)?.bg,
                    { "border-0": true }
                  )}
                >
                  <p className=" whitespace-nowrap p-1 text-gray-700">
                    {t.elementId}
                  </p>
                </div>
                <div
                  className={clsx(
                    "w-1/12 flex justify-center items-center relative ",
                    setColor(t.error, t.trxCode)?.bgFul
                  )}
                >
                  <p className=" whitespace-nowrap -rotate-90 p-1 text-white">
                    {t.uniqueId}
                  </p>
                </div>

                <div className="w-10/12">
                  <div className="flex justify-between items-center border-b border-b-gray-400">
                    <div className="name p-2  w-1/2 ">
                      {t.trxCode ? (
                        <span className="w-full block  whitespace-nowrap text-ellipsis overflow-hidden">
                          {languages[language.language.short].pageTransfer.s25}:
                          {t.trxCode}
                        </span>
                      ) : null}
                    </div>
                    <div
                      className={clsx(
                        "w-1/2 whitespace-nowrap text-ellipsis  p-2 text-left",
                        {
                          "text-right": !(
                            language.language.short === "fa" ||
                            language.language.short === "ar"
                          ),
                        }
                      )}
                    >
                      {t.error ? (
                        <div className="w-full relative">
                          <div
                            className="grid grid-flow-col auto-cols-max  justify-end gap-1  relative cursor-pointer"
                            onClick={showErrorDetails}
                          >
                            <FaInfoCircle className="text-gray-800" size={20} />
                            <span>{setColor(t.error, t.trxCode)?.status}</span>
                          </div>
                          <div
                            className={clsx(
                              "absolute -top-8 bg-red-50 z-50 rounded-sm p-1 border border-dashed border-red-600 hidden ",
                              {
                                "right-0": !(
                                  language.language.short === "fa" ||
                                  language.language.short === "ar"
                                ),
                                "left-0 ":
                                  language.language.short === "fa" ||
                                  language.language.short === "ar",
                              }
                            )}
                          >
                            {t.error}
                          </div>
                        </div>
                      ) : (
                        setColor(t.error, t.trxCode)?.status
                      )}
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="flex justify-between items-center">
                      <div className="name whitespace-nowrap p-2 ">
                        {languages[language.language.short].pageTransfer.s26}
                      </div>
                      <div className="border-b border-b-green-500 border-dashed w-full"></div>
                      <div className="text-left whitespace-nowrap p-2">
                        {language.language.rtl
                          ? transform.convertMoney(t.amount, true)
                          : transform.convertMoney(t.amount)}{" "}
                        {languages[language.language.short].pageTransfer.s29}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="name whitespace-nowrap p-2 ">
                        {languages[language.language.short].pageTransfer.s27}
                      </div>
                      <div className="border-b border-b-green-500 border-dashed w-full"></div>
                      <div className="text-left whitespace-nowrap p-2">
                        {t.customerId}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm whitespace-nowrap text-ellipsis w-full overflow-hidden border border-t-slate-400 p-1 flex gap-1">
                    <div>
                      {languages[language.language.short].pageTransfer.s28}:
                    </div>
                    <div className="text-ellipsis  overflow-hidden ">
                      {t.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="w-full text-center mt-8">
        {languages[language.language.short].pageTransfer.s33}
      </div>
    );
  };

  return (
    <LayoutMain>
      <div
        className={clsx("w-full", {
          "ltr-direction": !(
            language.language.short === "fa" || language.language.short === "ar"
          ),
        })}
      >
        <div className="w-full grid grid-flow-col justify-center gap-6 place-items-center">
          <div className="text-2xl">
            {!(
              language.language.short === "fa" ||
              language.language.short === "ar"
            ) ? (
              <Link to="/transaction/transfer?c=batch">
                <FaArrowLeft className="cursor-pointer" />
              </Link>
            ) : (
              <Link to="/transaction/transfer?c=batch">
                <FaArrowRight className="cursor-pointer" />
              </Link>
            )}
          </div>
          <h3 className="text-center bg-red my-8">
            {languages[language.language.short].pageTransfer.s19}
          </h3>
          <div>&nbsp;</div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center">
          <img src={loadingIcon} alt="loading" className="fixed top-52" />
        </div>
      ) : (
        renderResult()
      )}
    </LayoutMain>
  );
}
