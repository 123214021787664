import LayoutPublic from "../components/LayoutPublic";

import { FaChevronDown, FaScaleBalanced } from "react-icons/fa6";
import config from "../configs/config";

export default function Privacy() {
  const openBox = (e: any) => {
    const divs = e.currentTarget.parentElement.getElementsByTagName("div");
    Object.values(divs).forEach((div: any) => {
      div.classList.add("hidden");
      div.classList.remove("accordion");
    });

    e.currentTarget.nextElementSibling.classList.remove("hidden");
    e.currentTarget.nextElementSibling.classList.add("accordion");
  };

  if (config.domainName === "yee") {
    return (
      <LayoutPublic>
        <div className="rtl-direction p-2 leading-8">
          <h2 className="grid grid-flow-col auto-cols-max gap-2 items-center text-2xl justify-center">
            <FaScaleBalanced size={30} />
            <span></span>
          </h2>
        </div>
      </LayoutPublic>
    );
  }
  return (
    <LayoutPublic>
      <div className="rtl-direction p-2 leading-8">
        <h2 className="grid grid-flow-col auto-cols-max gap-2 items-center text-2xl justify-center">
          <FaScaleBalanced size={30} />
          <span>قوانین</span>
        </h2>
        <p className="mt-5">
          <span className="font-bold">تاریخ اعمال:</span> فروردین ۱۳۹۷
        </p>
        <p>
          <span className="font-bold">آخرین بروزرسانی:</span> مهر ۱۳۹۷
        </p>

        <h3
          onClick={openBox}
          className="w-full bg-emerald-100 flex items-center text-gray-900 gap-1 p-2 justify-between mt-6  cursor-pointer"
        >
          مقدمه <FaChevronDown />
        </h3>
        <div className="w-full transition-all hidden p-2 bg-emerald-50">
          <p className="mb-3">
            این توافقنامه به منزله قراردادی بین شما و سایت moneasy.ir است که
            استفاده شما را از سرویس ها و حساب های Moneasy کنترل می کند. این
            قوانین مطابق با قوانین جمهوری اسلامی ایران و قانون تجارت الکترونیک و
            قوانین بانک مرکزی است.
          </p>
          <p className="mb-3">
            ایجاد حساب کاربری در Moneasy به مفهوم موافقت شما با تمامی شرایط و
            قوانین این توافقنامه است. لطفاً آن را با دقت مطالعه بفرمایید. برای
            Moneasy این حق محفوظ است که در هر زمانی و به هر دلیلی مطالب پیش رو
            را تغییر دهد، در این صورت یک هفته زودتر به کاربران اطلاع داده می شود
            و پس از آن اعمال خواهد شد.
          </p>
        </div>

        <h3
          className="w-full bg-gray-100 flex items-center justify-between text-gray-900 gap-1 p-2  cursor-pointer"
          onClick={openBox}
        >
          حساب کاربری <FaChevronDown />
        </h3>
        <div className="w-full transition-all hidden bg-gray-50 p-2 ">
          <p className="mb-3">
            تمامی حساب های کاربری ایجاد شده، چه از طریق وب‌سایت و چه با استفاده
            از اپلیکیشن اندروید کیف پول مانیزی، به شما امکان انتقال وجه آنلاین
            به دیگران و شارژ رایگان حساب کاربری را می دهند.
          </p>
          <p className="mb-3">
            فرض ما بر این است که اطلاعات کاربری وارد شده صحیح است. در غیر این
            صورت پیامک یا ایمیل های ارسالی ما و همچنین فرایند انتقال وجه ممکن
            است به دلیل نبود اطلاعات صحیح به طور موثر عمل نکند.
          </p>
          <p className="mb-3">
            بر عهده کاربر است که اطلاعات خود را به روز نگه دارد. ما وظیفه داریم
            پس از ایجاد حساب کاربری توسط شما، از طریق پیامک یا ایمیل (به مقتضای
            نوع حساب کاربری)، امکان تایید حساب مربوطه را برای شما فراهم آوریم.
            هنگامی که شما شماره موبایل یا ایمیل خود را وارد می‌کنید، این امکان
            را به ما می‌دهید که از این طریق با شما در ارتباط باشیم تا بتوانیم
            ارائه خدمات دهیم یا مشکلات و تحقیقات قانونی را پیگیری کنیم.
          </p>
          <p className="mb-3">
            ممکن است مانیزی به اقتضای نیاز در رابطه با خدمت رسانی، از کاربر
            اطلاعات تکمیلی درخواست کند. در صورت عدم تکمیل اطلاعات امکان دارد
            خدمات داده شده به حساب کاربر مورد نظر محدود یا معلق شود.
          </p>
          <p className="mb-3">
            در صورت احراز عدم رعایت قانون توسط کاربر، ممکن است حساب بسته شود. در
            این صورت مطابق با دستور مراجع قضایی و براساس قوانین جاری، برای مبالغ
            داخل حساب تعیین تکلیف خواهد شد.
            <br />
            در صورتی که مانده حساب صفر باشد و بیش از یک سال از حساب استفاده
            نشود، آن حساب کاربری بسته خواهد شد. بسته شدن یا غیرفعال شدن حساب، به
            صورت ایمیل یا پیام به کاربر اطلاع داده می‌شود.
            <br />
            اشتراک اطلاعات شخصی کاربر فقط براساس قوانین جاری و با حکم رسمی مراجع
            ذی ربط انجام می‌شود.
          </p>
        </div>

        <h3
          className="w-full bg-emerald-100 flex items-center text-gray-900 gap-1 p-2 justify-between  cursor-pointer"
          onClick={openBox}
        >
          انتقال پول <FaChevronDown />
        </h3>
        <div className="w-full transition-all hidden bg-emerald-50 p-2 ">
          <p>
            هر سازمانی که شما به آن پولی انتقال می دهید، مجاز است که تراکنش
            انجام شده را در مدت زمانی مشخص بررسی ‌کند و سپس آن را تایید نماید.
            تراکنش های با ریسک بالا تا زمان احراز ‌‌صحت معلق خواهند شد. استرداد
            مبالغ تراکنش های ردشده یا برگشتی، براساس مدل های برگشتی بانک‌ها و تا
            زمان ۷۲ ساعت خواهد بود. شما امکان برداشت از Moneasy و انتقال پول به
            یک حساب بانکی را دارید، در این صورت وارد کردن اطلاعات صحیح برعهده
            شما است. وقتی در سیستم برداشتی انجام می‌شود، ما می توانیم این فرایند
            را به تاخیر اندازیم تا بررسی های لازم روی تراکنش انجام شود، از جمله
            گرفتن تایید از شما برای انجام تراکنش. تراکنش های نامعتبر به دلیل
            اشتباه فردی، مثل وارد کردن مبلغ ناصحیح، بر عهده کاربر است.
          </p>
          <p className="mb-3">
            هر نوع عملیات مالی در سایت محدودیتی روی مبلغ انتقال دارد که مشخص شده
            است. همچنین هر انتقال تعرفه مخصوص خود را دارد. برای اطلاع از تعرفه
            های هر نوع انتقال می‌توانید به صفحه تعرفه ها مراجعه کنید.
          </p>
        </div>

        <h3
          className="w-full bg-gray-100 flex items-center text-gray-900 gap-1 p-2 justify-between  cursor-pointer"
          onClick={openBox}
        >
          <span>خطاها و تراکنش‌ها غیرمجاز</span> <FaChevronDown />
        </h3>

        <div className="w-full transition-all hidden bg-gray-50 p-2 ">
          <p className="mb-3">
            در صورتی که تراکنشی به دلیل خطای سایت انجام نشود یا نامعتبر شود، بر
            عهده ماست که مبلغ را به حساب کاربر برگردانیم. محدودیت ها و اختلالات
            شبکه بانکی یا اینترنتی جزء خطاهای سایت محسوب نمی شود و در صورت بروز
            مشکل مسئولیتی برعهده Moneasy نیست.
          </p>
          <p className="mb-3">
            اطلاعات تمامی تراکنش های انجام شده در حساب شما نگه داشته می‌شود و
            شما با ورود به حساب کاربری خود می‌توانید آن ها را در بخش تراکنش‌ها
            مشاهده کنید. اگر تراکنشی از حساب کاربری شما انجام شود که مورد تایید
            شما نیست، در صورتی که فرد انجام دهنده تراکنش با رضایت شما به حسابتان
            دسترسی داشته است یا مشکل این تراکنش به دلیل خطای فردی کاربر است،
            سایت Moneasy مسئولیتی در قبال بازپرداخت برعهده نمی‌گیرد.
          </p>
        </div>

        <h3
          className="w-full  bg-emerald-100 flex items-center text-gray-900 gap-1 p-2 justify-between  cursor-pointer"
          onClick={openBox}
        >
          <span>فعالیت های ممنوع</span> <FaChevronDown />
        </h3>
        <div className="w-full transition-all hidden bg-emerald-50 p-2 ">
          <p>
            در ارتباط با سایت Moneasy و استفاده از سرویس‌های آن، فعالیت های زیر
            مجاز نیستند:
          </p>
          <ul className="list-disc list-inside p-2">
            <li>
              نقض این تعهدنامه و هر مقرراتی که در قبال Moneasy پذیرفته&zwnj;اید
            </li>
            <li> تخلف از قوانین و مقررات رسمی</li>
            <li>
              نقض قانون کپی رایت در قبال Moneasy یا شخص ثالث در ارتباط با این
              سایت
            </li>
            <li>
              دادن اطلاعات غلط یا گمراه کننده یا ممانعت از دادن اطلاعات برای
              تشخیص هویت در مواقع لزوم{" "}
            </li>
            <li>شرکت در یک فعالیت یا تراکنش جعلی یا مشکوک</li>
            <li>
              شرکت در فعالیتی تهدید آمیز، متقلبانه یا آزار دهنده نسبت به همکاران
              یا کاربران ما{" "}
            </li>{" "}
            استفاده از Moneasy در فعالیت&zwnj;های مرتبط با:{" "}
            <li>
              مواد مخدر، مواد کنترل شده خاص یا سایر محصولاتی که خطر ایمنی مصرف
              کننده را تهدید می&zwnj;کنند{" "}
            </li>
            <li>
              اقلامی که باعث تشویق، ترویج، تسهیل یا آموزش سایرین برای مشارکت در
              فعالیت غیرقانونی می&zwnj;شود{" "}
            </li>
            <li>
              اقلامی که هر گونه حق چاپ، علامت تجاری، حق نشر یا حریم خصوصی یا هر
              گونه حق انحصاری دیگر را، تحت قوانین هر حوزه قضایی، نقض می&zwnj;کند{" "}
            </li>
            <li>
              {" "}
              اقلامی که مبادله آنها طبق قانون جمهوری اسلامی ایران ممنوع یا دارای
              نظامات خاصی است{" "}
            </li>
            <li> اقلامی که مستهجن یا قبیح تلقی می&zwnj;شوند</li>
            <li>خدمات یا مواد جنسی بدون مجوز</li>
            <li>
              مهمات، اسلحه گرم یا قطعات و لوازم جانبی آن، اسلحه سرد و اقلام
              قاچاق
            </li>{" "}
            استفاده از Moneasy در فعالیت های مرتبط با:{" "}
            <li>افشای اطلاعات شخص ثالث با نقض قانون</li>
            <li>حمایت از شرکت&zwnj;های هرمی یا مارکتینگ چند سطحی</li>
            <li>فعالیت&zwnj;های بخت آزمایی، شرط بندی و مصادیق قمار</li>
            <li>دریافت یا پرداخت در امور رشوه خواری و فساد اداری</li>
            <li>
              {" "}
              خرید و فروش وجوه نقد، اوراق بهادار، کارت&zwnj;های هدیه، تبدیل ارز
            </li>
            <li>خرید و فروش ابزارهای هکینگ و عبور از فیلترینگ</li>
            <li>
              سوءاستفاده از کارت&zwnj;های اعتباری و حساب&zwnj;های بانکی از طریق
              Moneasy
            </li>
            <li>افشا کردن اطلاعات کاربران دیگر بدون اجازه آن&zwnj;ها</li>
            <li>
              اعمال هرگونه ویروس، بدافزار، تروجان و یا لود بالای غیرمنطقی بر روی
              سیستم&zwnj;های Moneasy{" "}
            </li>
            <li>
              اعمال هرگونه فعالیتی که موجب از دست رفتن سرویس&zwnj;ها روی ISPها
              یا تامین کنندگان مالی و پردازشی ما شود{" "}
            </li>
          </ul>
        </div>
        <h3
          className="w-full bg-gray-100 flex items-center text-gray-900 gap-1 p-2 justify-between  cursor-pointer"
          onClick={openBox}
        >
          <span>سایر</span> <FaChevronDown />
        </h3>
        <div className="w-full transition-all hidden bg-gray-50 p-2 ">
          <p>
            <span className="font-bold"> محدودیت سرویس‌دهی:</span> ‫Moneasy بانک
            نیست و سرویس‌های بانکداری ارایه نمی دهد، Moneasy یک تامین کننده
            خدمات پرداخت آنلاین است و تنها سرویس‌های پردازشی پرداخت ارایه می
            دهد. ‫Moneasy هیچ کنترل یا مسئولیتی در قبال خدمات یا محصولاتی که با
            سرویس‌های Moneasy پرداخت شده اند، ندارد. و هویت هیچ یک از کاربران را
            تضمین نمی کند.
          </p>
          <p className="mt-2">
            <span className="font-bold"> استفاده از اطلاعات شخصی:</span> اگر شما
            اطلاعاتی از دیگر کاربران Moneasy دریافت کردید، موظف به حفاظت از آن
            اطلاعات هستید و فقط می توانید در محدوده سرویس‌های Moneasy از آن‌ها
            استفاده کنید. شما نمی‌توانید آن اطلاعات را افشا کنید یا بدون رضایت
            صریح کاربر، از آن استفاده تجاری نمایید.
          </p>
          <p className="mt-2">
            <span className="font-bold"> حریم شخصی:</span> حفاظت از حریم شخصی
            شما برای ما بسیار اهمیت دارد. برای فهم بهتر چگونگی آن صفحه حریم شخصی
            را مطالعه بفرمایید.
          </p>
          <p className="mt-2">
            <span className="font-bold">رمزها:</span> حفظ امنیت رمز عبور و کد
            امنیتی یا هر کد دیگری که در Moneasy استفاده می کنید با خود
            شماست.واحد پول: برای سهولت در تراکنش‌ها، کلیه مبالغی که در Moneasy
            ارائه می‌شود به «تومان» است. لذا در این امر توجه لازم را مبذول
            بفرمایید.
          </p>
          <p className="mt-2">
            <span className="font-bold">مشتریان:</span>
            برای کار موثر و مفید با مشتریان، پس از احراز هویت و تایید ، ما
            سرویسهای موردنیاز و مستندات مربوط به آنها را در اختیار قرار میدهیم.
          </p>
          <p className="mt-2">
            <span className="font-bold">شخص ثالث (Third Party):</span>
            اشخاص یا سازمانی‌هایی وجود دارند که Moneasy با آن‌ها تبادل داده
            می‌کند، به آن‌ها سرویس می‌دهد و از آن‌ها سرویس می‌گیرد. کار با اشخاص
            ثالث در راستای رسیدن به اهداف و ارایه خدمات بهتر صورت می‌گیرد. از
            جمله این اشخاص و سازمان‌ها می‌توان به : بانک‌ها، درگاه پرداخت سداد،
            پرداخت نوين آرين، درگاه پرداخت پاسارگارد، باشگاه دانش‌پژوهان دانشگاه
            آزاد و … اشاره کرد. ‫Moneasy با اختیار شخصی خود ، حق معلق کردن یا
            بستن این توافقنامه یا محدود کردن یا بستن حساب و سرویس‌هایی که کاربر
            استفاده می‌کند، در هر زمان و به هر دلیلی داراست، با این شرایط که این
            موضوع به اطلاع کاربر برسد و در صورت بسته شدن حساب، مبالغی که
            محدودیتی قانونی روی آن‌ها نیست قابل برداشت باشد. کاربر مجاز نیست که
            هیچ یک از توافق‌های بین دو طرف را بدون رضایت کتبی Moneasy انتقال
            دهد.
          </p>
        </div>
      </div>
    </LayoutPublic>
  );
}
