import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      username: "",
      name: "",
      uniqueName: "",
      profileAvatar: null,
      roles: [],
      creationDate: "",
      mainAccount: {
        accNumber: "",
        lastUpdate: "",
        balance: "0",
        unit: "",
        realTimeBalanceDepositUpdate: true,
      },
      phoneNumber: "",
      pinCodeSet: false,
      redisKey: "",
    },
  },
  reducers: {
    iSetUser: (state, action) => {
      state.user = action.payload;
      console.log("3", state);
    },
  },
});

export const { iSetUser } = userSlice.actions;

export default userSlice.reducer;

// export const descremntAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(decrement(amount));
//   }, 1000);
// };
