import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./languageSlice";
import docReducer from "./docSlice";
import lightboxReducer from "./lightboxSlice";
import sideMenuReducer from "./sideMenuSlice";
import userReducer from "./userSlice";
import pwaReducer from "./pwaSlice";

export default configureStore({
  reducer: {
    language: languageReducer,
    doc: docReducer,
    lightbox: lightboxReducer,
    sideMenu: sideMenuReducer,
    pwa: pwaReducer,
    user: userReducer,
  },
});
