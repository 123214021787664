import { useEffect, useState } from "react";

import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setShowPWAInstalledButton } from "../../redux/pwaSlice";
import config from "../../configs/config";
import languages from "../../languages/languages";

function BamakNotification({ prompt, promptToInstall }: any) {
  const dispatch = useDispatch();

  const language = useSelector((state: any) => state.language);
  const { showPWAInstallButton } = useSelector((state: any) => state.pwa);
  let showInstallPrompt;
  if (
    window &&
    window.navigator &&
    ((window as any).navigator as any).getInstalledRelatedApps
  ) {
    const result = ((window as any).navigator as any).getInstalledRelatedApps();
    result.then(() => {});
    showInstallPrompt = async () => {
      try {
        await promptToInstall();
        if (prompt)
          prompt.userChoice.then((choiceResult: any) => {
            if (choiceResult.outcome === "accepted") {
              // console.log("User accepted the A2HS prompt");
              // create localStorage

              dispatch(setShowPWAInstalledButton(false));

              localStorage.setItem("isPWAInstalled", "true");
            } else {
              // console.log("User dismissed the A2HS prompt");
            }
            // deferredPrompt = null;
          });
      } catch (e) {
        showInstallPrompt = () => {};
        console.log(e);
        // console.log(e);
      }
    };
  } else {
    dispatch(setShowPWAInstalledButton(false));
  }

  useEffect(() => {
    let isPWAInstalled = sessionStorage?.getItem("isPWAInstalled");

    if (isPWAInstalled === "true") {
      return;
    }

    try {
      let isPWAInstalled = localStorage?.getItem("isPWAInstalled");
      if (isPWAInstalled === "true") {
        dispatch(setShowPWAInstalledButton(false));
      } else {
        dispatch(setShowPWAInstalledButton(true));
      }
    } catch (error) {}
  }, []);

  return (
    <div
      className={
        showPWAInstallButton
          ? " btext-center p-1 bg-white flex justify-between items-center border-b border-b-gray-100"
          : "hidden"
      }
    >
      <div className="flex items-center my-2">
        <span
          onClick={() => {
            dispatch(setShowPWAInstalledButton(false));
            sessionStorage.setItem("isPWAInstalled", "true");
          }}
        >
          <FaTimes className="text-xl cursor-pointer text-gray-400" />
        </span>
        <div className="mr-4">
          <img
            src={`/pwa/${config.domainName}/shared/32.png`}
            className="w-9"
            alt="مانیزی"
          />
        </div>
        <span className="whitespace-nowrap flex justify-center items-center mx-1 ">
          {languages[language.language.short].addToHomePage}
        </span>
      </div>
      <button
        className="border-0 bg-teal-500 py-2 px-5 mx-2 flex justify-center items-center rounded text-base text-white "
        type="button"
        onClick={showInstallPrompt}
      >
        {languages[language.language.short].yes}
      </button>
    </div>
  );
}

export default BamakNotification;
