import {
  FaCheck,
  FaCircleInfo,
  FaFileArrowDown,
  FaMoneyBill1,
  FaPencil,
  FaUser,
} from "react-icons/fa6";
import loadingGIF from "../../themes/blue/images/loading.gif";
import LayoutMain from "../../components/LayoutMain";
import translator from "../../utils/translator";
import xls from "../../themes/blue/images/xls-download.webp";
import clsx from "clsx";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import languages from "../../languages/languages";
import { Link, useParams, useSearchParams } from "react-router-dom";
import profile from "../../themes/blue/images/profile.jpg";
import {
  changeLightBoxState,
  setLevel,
  setPinDet,
} from "../../redux/lightboxSlice";
import { transform } from "../../utils/persianTransform";
import ExcelUpload from "../../components/upload/ExcelUpload";
var shamsi = require("shamsi-date-converter");
export default function Transfer() {
  const dispatch = useDispatch();

  const [msg, setMSG] = useState("");
  const [state, setState] = useState({
    filledUsername: "",
    filledAmount: "",
    name: "",
    description: "",
    username: "",
    uniqueName: "",
    profileAvatar: "",
    page: "payment-fill",

    when: "",
    amount: "",
    trxCode: "",
    balance: "",
    sourceUsername: "",
    destinationUsername: "",
    accountUniqId: "",
    persianType: "",
    profileAvatarUrl: "",
    sign: "",
  });

  const language = useSelector((state: any) => state.language);
  const { pinDet } = useSelector((state: any) => state.lightbox);
  const { user } = useSelector((state: any) => state.user);

  const { userPhoneOrEmail } = useParams();

  const paymentConfirm = async () => {
    let filledAmount = state.filledAmount
      ? transform.persianNumberToEng(state.filledAmount)
      : "0";
    filledAmount = +filledAmount.replace(/[^0-9]/g, "");

    try {
      if (!state.filledUsername) {
        return setMSG(languages[language.language.short].pageTransfer.s1);
      }

      if (!state.filledAmount) {
        return setMSG(languages[language.language.short].pageTransfer.s2);
      }
      const res = await axios.get(`/api/find/username/${state.filledUsername}`);

      setState({
        ...state,
        filledAmount,
        ...res.data,
        page: "payment-confirm",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [pin, setPin] = useState("");

  const pay = async () => {
    let filledAmount = state.filledAmount
      ? transform.persianNumberToEng(state.filledAmount)
      : "0";
    filledAmount = +filledAmount.replace(/[^0-9]/g, "");
    try {
      //check is user set pincode

      if (transferType === "0") {
        const res = await axios.post(
          "/api/transaction/user2user",
          {
            username: state.filledUsername,
            amount: filledAmount,
            description: state.description,
          },
          {
            headers: {
              Pincode: pinDet.pin,
            },
          }
        );
        dispatch(setLevel(""));
        dispatch(changeLightBoxState(false));
        dispatch(setPinDet({ msg: "", pin: "" }));

        setState({ ...state, ...res.data, page: "pay" });
      } else {
        const res = await axios.post(
          "/api/transaction/user2user/alt",
          {
            username: state.filledUsername,
            amount: filledAmount,
            description: state.description,
            altAccountUniqueId: transferType,
          },
          {
            headers: {
              Pincode: pinDet.pin,
            },
          }
        );

        dispatch(setLevel(""));
        dispatch(changeLightBoxState(false));
        dispatch(setPinDet({ msg: "", pin: "" }));

        setState({ ...state, ...res.data, page: "pay" });
      }
    } catch (error: any) {
      const { data } = error.response;
      if (data === "Pin Code in Header is Empty") {
        dispatch(setLevel("pinCode"));
        dispatch(changeLightBoxState(true));
      }

      if (data === "Pin Code Not Match") {
        dispatch(setLevel("pinCode"));
        dispatch(changeLightBoxState(true));
        pinDet.aware &&
          dispatch(
            setPinDet({
              msg: languages[language.language.short].pageTransfer.s3,
            })
          );
      }

      if (data === "not enough balance") {
        dispatch(setLevel("pinCode"));
        dispatch(changeLightBoxState(true));
        pinDet.aware &&
          dispatch(
            setPinDet({
              msg: languages[language.language.short].pageTransfer.s4,
            })
          );
        // setMSG("موجودی کافی نیست");
        setMSG(languages[language.language.short].pageTransfer.s4);
      }
    }
  };

  useEffect(() => {
    setState({
      ...state,
      filledUsername: userPhoneOrEmail ? userPhoneOrEmail : "",
    });
  }, []);

  const [transferType, setTransferType] = useState("0");
  let transferUnitPersian =
    languages[language.language.short].dynamicWords.toman;
  switch (transferType) {
    case "0":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.toman;
      break;
    case "113":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dollar;
      break;
    case "114":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.euro;
      break;
    case "115":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dirham;
  }

  const [unitsList, setUnitsList] = useState<any>([]);
  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await axios.get("/api/alt-account/customer-list");
        setUnitsList([...unitsList, ...data].slice(0, 3));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const renderAccountUnit = (unit: string) => {
    let transferUnitPersian =
      languages[language.language.short].dynamicWords.toman;
    switch (unit) {
      case "تومان":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.toman;
        break;
      case "دلار":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dollar;
        break;
      case "درهم":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dirham;
        break;
      case "یورو":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.euro;
    }
    return transferUnitPersian;
  };

  useEffect(() => {
    pinDet.aware && pay();
  }, [pinDet.aware]);

  const [contacts, setContacts] = useState([]);

  const giveContactsSuggestion = async (e: any) => {
    try {
      const { data }: any = await axios.get(
        "/api/contact/search/" + e.target.value
      );

      typeof data !== "string" ? setContacts(data) : setContacts([]);
    } catch (error) {
      setContacts([]);
    }
  };

  const amountFunc = (e: any) => {
    let amount;
    try {
      amount = transform.persianNumberToEng(e.target.value);

      amount = amount.replace(/[^0-9]/g, "");
    } catch (e: any) {
      amount = "";
    }

    if (languages[language.language.short].rtl) {
    }

    setState({
      ...state,
      filledAmount: amount
        ? languages[language.language.short].rtl
          ? transform.convertMoney(amount, true)
          : transform.convertMoney(amount)
        : "",
    });
  };

  const amountFunc2 = () => {
    let amount;
    try {
      amount = transform.persianNumberToEng(state.filledAmount);

      amount = amount.replace(/[^0-9]/g, "");
    } catch (e: any) {
      amount = "";
    }

    setState({
      ...state,
      filledAmount: amount
        ? languages[language.language.short].rtl
          ? transform.convertMoney(amount, true)
          : transform.convertMoney(amount)
        : "",
    });
  };

  useEffect(() => {
    amountFunc2();
  }, [language]);

  const [show, setShow] = useState("single");

  const [batchs, setBatchs] = useState([]);

  const fetchBatchList = async () => {
    try {
      const res = await axios.get(
        "/api/transaction/transfer/batch/list?page=1&size=40"
      );

      setBatchs(res.data.data);
    } catch {
      console.log("Error");
    }
  };

  useEffect(() => {
    fetchBatchList();
  }, []);

  const renderBacthSwitch = (status: string) => {
    switch (status) {
      case "INITIAL":
        return {
          title: translator(
            languages[language.language.short].pageTransfer.s14,
            language.language.short
          ),
          color: { "bg-gray-600": true },
        };
      case "PROCESSED":
        return {
          title: translator(
            languages[language.language.short].pageTransfer.s15,
            language.language.short
          ),
          color: { "bg-gray-600": true },
        };
      case "CANCELLED":
        return {
          title: translator(
            languages[language.language.short].pageTransfer.s16,
            language.language.short
          ),
          color: { "bg-red-600": true },
        };
      case "ERROR":
        return {
          title: translator(
            languages[language.language.short].pageTransfer.s17,
            language.language.short
          ),
          color: { "bg-red-600": true },
        };
      case "DONE":
        return {
          title: translator(
            languages[language.language.short].pageTransfer.s18,
            language.language.short
          ),
          color: { "bg-green-600": true },
        };
    }
    return { title: "", color: { bgd: true } };
  };

  function timeConverter(UNIX_timestamp: number) {
    var a = new Date(UNIX_timestamp);
    let mypersiandate = a.toLocaleDateString("fa-IR");

    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var day = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();

    var georgian = day + " " + month + " " + year + " " + hour + ":" + min;
    mypersiandate += ` ${hour}:${min}`;
    mypersiandate = transform.engNumberToPersian(mypersiandate);
    return [georgian, mypersiandate];
  }

  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.get("c") &&
      searchParams.get("c") === "batch" &&
      user.roles.includes("CAN_BATCH_TRANSFER")
    ) {
      setShow("batch");
    }
  }, [user]);

  return (
    <LayoutMain>
      <div
        className={clsx("w-full", {
          "ltr-direction": !(
            language.language.short === "fa" || language.language.short === "ar"
          ),
        })}
      >
        {user?.roles?.includes("CAN_BATCH_TRANSFER") ? (
          <select
            onChange={(e) => setShow(e.target.value)}
            className={clsx({
              "w-full max-w-40 mx-auto block bg-gray-400 text-white  focus:outline-none rounded my-4 p-1 ":
                true,
              "ltr-direction": !(
                language.language.short === "fa" ||
                language.language.short === "ar"
              ),
            })}
          >
            <option value="single">
              {languages[language.language.short].pageTransfer.s8}
            </option>
            <option
              selected={searchParams.get("c") === "batch" ? true : false}
              value="batch"
            >
              {languages[language.language.short].pageTransfer.s9}
            </option>
          </select>
        ) : (
          <h3 className="text-center bg-red my-8">&nbsp;</h3>
        )}
      </div>
      <div className={show === "batch" ? "w-full" : "hidden"}>
        <div className="my-8 w-full mx-auto grid grid-flow-col place-items-center auto-cols-max auto-rows-max place-content-center text-orange-500">
          <Link
            to="https://epay.yechat.org/api/transaction/transfer/batch/sample-file"
            className="mx-auto grid grid-flow-col place-items-center auto-cols-max auto-rows-max place-content-center text-orange-500"
          >
            <FaFileArrowDown className="text-2xl" />
            {languages[language.language.short].pageTransfer.s30}
          </Link>
        </div>

        <ExcelUpload fetchBatchList={fetchBatchList} />

        <div className="h-1 w-full bg-slate-200 mt-8  mb-4"></div>

        <h3 className="text-center bg-red  mb-4">
          {languages[language.language.short].pageTransfer.s11}
        </h3>
        {batchs.map((b: any) => (
          <Link
            to={`/transaction/transfer/batches/${b.accountUniqueCode}`}
            key={b.savedFileName}
          >
            <div className="flex mx-auto border border-gray-300 rounded mb-4 w-11/12 relative">
              <div className="myPic w-24 h-24 p-1 flex items-center">
                <img src={xls} className="rounded" alt="bank" />
              </div>
              <div className="myCenter flex flex-col justify-between w-full">
                <div className="flex gap-2 justify-between items-center">
                  <h2 className="p-1 text-sm whitespace-nowrap text-ellipsis overflow-hidden ltr-direction">
                    {language.language.short === "fa"
                      ? timeConverter(b.created)[1]
                      : timeConverter(b.created)[0]}
                  </h2>
                  <span
                    className={clsx(
                      "mx-1 px-1  text-white  w-20 text-center",
                      renderBacthSwitch(b.state).color
                    )}
                  >
                    {renderBacthSwitch(b.state).title}
                  </span>
                </div>
                <h2 className="p-1 text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                  {languages[language.language.short].pageTransfer.s12}:{" "}
                  {b.originFileName}
                </h2>
                <h2 className="p-1 text-sm">
                  {languages[language.language.short].pageTransfer.s13}:{" "}
                  {b.errors ? b.errors : "-"}
                </h2>
              </div>
            </div>
          </Link>
        ))}
        <div className="w-full h-20"></div>
      </div>
      <div className={show === "single" ? "w-full" : "hidden"}>
        <div
          className={state.page === "payment-fill" ? "payment-fill" : "hidden"}
        >
          <h3 className="text-center bg-red my-8">
            {languages[language.language.short].moneyTransfer}
          </h3>
          <select
            onChange={(e: any) => {
              setTransferType(e.target.value);
              setMSG("");
            }}
            value={transferType}
            className="max-w-md block focus:outline-none mx-auto w-11/12 bg-gray-400 text-white rounded p-1 my-4"
          >
            <option value="0">
              {languages[language.language.short].mainAccount}
            </option>
            {unitsList.map((item: any, index: any) => (
              <option key={index} value={item.altAccountMetaData.uniqueId}>
                {languages[language.language.short].account}{" "}
                {renderAccountUnit(item.altAccountMetaData.unit)}
              </option>
            ))}
          </select>
          <div className="relative w-11/12 max-w-md mx-auto ">
            <div className="bg-white border border-gray-300 w-full flex items-center  text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
              <div className="p-2">
                <FaUser className="text-2xl" />
              </div>
              <input
                type="text"
                autoComplete="off"
                placeholder={
                  languages[language.language.short].destinationUsername
                }
                className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-700"
                onChange={(e) => {
                  setState({ ...state, filledUsername: e.target.value });
                  giveContactsSuggestion(e);
                }}
                value={state.filledUsername}
              />
            </div>
            <div className="absolute w-full bg-slate-100">
              {contacts.length ? (
                <div className="w-full block  bg-gray-50 outline-none p-1  border border-gray-300 text-gray-600 rounded">
                  {contacts.map((c: any) => (
                    <div
                      className="border-b border-b-gray-200 p-1 child  last:border-0 cursor-pointer hover:bg-gray-100 py-2"
                      onClick={(e: any) => {
                        setState({
                          ...state,
                          filledUsername: c.userMailOrPhone,
                        });

                        setContacts([]);
                      }}
                      key={c.userMailOrPhone}
                    >
                      {`${c.name} | ${c.userMailOrPhone}`}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
            <div className="p-2">
              <FaMoneyBill1 className="text-2xl" />
            </div>
            <input
              type="text"
              className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500"
              autoComplete="off"
              onChange={amountFunc}
              placeholder={`${
                languages[language.language.short].amount
              } ${transferUnitPersian}`}
              value={state.filledAmount}
            />
          </div>
          <p
            className={
              state.filledAmount
                ? "text-center w-full mt-3 text-gray-600 text-sm"
                : "hidden"
            }
          >
            {languages[language.language.short].pageTransfer.s7}:{" "}
            {state.filledAmount} {languages[language.language.short].toman}
          </p>
          <textarea
            className="bg-white border border-gray-300 w-11/12 outline-none max-w-md mx-auto rounded h-20 block mt-8 resize-none p-2 text-sm"
            placeholder={
              languages[language.language.short]
                .insertTransactionDescriptionHere
            }
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            value={state.description}
          ></textarea>
          <div className="clear-both"></div>
          <p className="text-center mt-8 ">{msg}</p>
          <button
            type="button"
            onClick={paymentConfirm}
            className="border border-teal-500  bg-teal-400 hover:bg-teal-500 text-white  rounded-lg  px-5 py-2.5 w-11/12 text-base max-w-44 block mx-auto"
          >
            {languages[language.language.short].continue}
          </button>
        </div>

        <div
          className={
            state.page === "payment-confirm" ? "payment-fill" : "hidden"
          }
        >
          <h3 className="text-center bg-red my-8">
            {languages[language.language.short].confirmationOfMoneyTransfer}
          </h3>

          <div className="bg-white pb-6 w-11/12  items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious flex flex-col">
            <div className="flex justify-start items-center w-full flex-col p-2">
              <img
                className="w-20 h-20 rounded-full"
                src={
                  state.profileAvatar
                    ? `https://moneasy.ir/portal/external/Avatar/${state.profileAvatar}`
                    : profile
                }
                alt="img"
              />

              <h3 className="text-gray-700 text-lg">{state.name}</h3>
            </div>
            <h3 className="text-base">
              {languages[language.language.short].amount}: {state.filledAmount}{" "}
              {transferUnitPersian}
            </h3>
            <div className="mt-8">
              <span>{languages[language.language.short].description}: </span>
              {state.description ? state.description : "-"}
            </div>
            <div className="w-full flex justify-center items-center gap-7 mt-8">
              <button
                type="button"
                onClick={() => {
                  setState({ ...state, page: "payment-fill" });
                  setMSG("");
                }}
                className="border orange-teal-500  bg-orange-400 hover:bg-orange-500 text-white  rounded-lg py-1  px-14  text-base max-w-44 flex justify-center items-center gap-1"
              >
                <FaPencil className="text-xl" />
                {languages[language.language.short].edit}
              </button>
              <button
                onClick={pay}
                type="button"
                className="border border-teal-500  bg-teal-400 hover:bg-teal-500 text-white  rounded-lg py-1  px-14 text-base max-w-44 flex justify-center items-center gap-1"
              >
                <span>
                  <FaCheck className="text-xl" />
                </span>
                {languages[language.language.short].confirmation}
              </button>
            </div>
          </div>
        </div>
        <div className={state.page === "pay" ? "payment-fill" : "hidden"}>
          <h3 className="text-center bg-red my-8 flex justify-center items-center gap-2">
            <FaCircleInfo className="text-2xl" />
            {languages[language.language.short].pageTransfer.s5}
          </h3>
          <h3 className="text-green-500 text-xl text-center">
            {languages[language.language.short].pageTransfer.s6}
          </h3>
        </div>
        <div className="h-60 w-full"> </div>
        <br />
      </div>
    </LayoutMain>
  );
}
