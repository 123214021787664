import { FaBars, FaLanguage, FaRegUser } from "react-icons/fa6";
import { FaSignOutAlt, FaTimes } from "react-icons/fa";
import { transform } from "../utils/persianTransform";

import { useDispatch, useSelector } from "react-redux";
import { boxStatus, changeLanguage } from "../redux/languageSlice";
import languages from "../languages/languages";

import logo from "../themes/blue/images/logo-text.png";

import { FaArrowRightArrowLeft, FaMinus, FaPlus } from "react-icons/fa6";

import { Link, useLocation, useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import { changeSideMenuState } from "../redux/sideMenuSlice";
import config from "../configs/config";
import clsx from "clsx";

export default function LayoutPublic({ children }: any) {
  const location = useLocation();

  const dispatch = useDispatch();

  const openLanguagesBox = (e: any) => {
    e.stopPropagation();
    // e.currentTarget.nextElementSibling.classList.toggle("hidden");
    dispatch(boxStatus("open"));
  };

  const changeLanguageFunc = (e: any, lng: any) => {
    dispatch(boxStatus("hidden"));
    dispatch(
      changeLanguage({
        short: lng,
        rtl: !!languages[lng].rtl,
      })
    );
  };

  const language = useSelector((state: any) => state.language);

  return (
    <div
      className={language.language.rtl ? "rtl-direction relative " : "relative"}
    >
      <div
        className={clsx(
          "flex items-center justify-between bg-teal-500 p-2 sticky z-10 mb-6",
          {
            "top-8": config.oldUrl ? true : false,
          }
        )}
      >
        <Link to="/" className="block mx-auto">
          <img src={logo} alt="logo" className="cursor-pointer max-w-32" />
        </Link>
      </div>
      {children}
    </div>
  );
}
