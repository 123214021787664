import haftSinEgg from "../themes/blue/images/haftsin.png";
import { useEffect, useRef, useState } from "react";
import VerificationInput from "react-verification-input";
import config from "../configs/config";
import {
  FaArrowLeft,
  FaArrowRotateRight,
  FaLanguage,
  FaRegSquare,
} from "react-icons/fa6";
import axios from "axios";
import messages from "../utils/messages";
import { Link, useNavigate } from "react-router-dom";
import languages from "./../languages/languages";
import { boxStatus, changeLanguage } from "../redux/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import { transform } from "../../src/utils/persianTransform";
import { FaArrowRight } from "react-icons/fa6";
import randomstring from "randomstring";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import {
  defaultCountries,
  parseCountry,
  PhoneInput as PhoneInput2,
  PhoneInputRefType,
  getCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import translator from "../utils/translator";
import CountdownTimer from "../components/CountdownTimer";
let interval: any;
export default function Login() {
  const language = useSelector((state: any) => state.language);
  const inputRef = useRef<HTMLInputElement>(null);
  const [otpResendTime, setOTPResendTime] = useState(0);
  const [visibleCountDown, setVisibleCountDown] = useState(false);
  const navigate = useNavigate();
  const [msg, setMSG] = useState<string>("");

  const [state, setState] = useState<any>({
    username: "",
    otpPassword: "",
    password: "",
    page: "isRegistered",
    rememberMe: null,
  });

  const checkIsUserRegisterd = async (e: any) => {
    e.preventDefault();
    try {
      let pagePrefix = "phone";
      let pageSuffix = "Register";
      let page = pagePrefix + pageSuffix;

      if (!state || !state.username || !state.username.trim()) {
        return setMSG(languages[language.language.short].loginPage.s11);
      }

      try {
        const {
          country,
          countryCallingCode: countryCode,
          nationalNumber,
        } = parsePhoneNumber(state.username) as {
          country: string;
          countryCallingCode: string;
          nationalNumber: string;
        };
      } catch (error) {
        return setMSG(languages[language.language.short].loginPage.s11);
      }

      const {
        country,
        countryCallingCode: countryCode,
        nationalNumber,
      } = parsePhoneNumber(state.username) as {
        country: string;
        countryCallingCode: string;
        nationalNumber: string;
      };

      if (!country) {
        return setMSG(languages[language.language.short].loginPage.s16);
      }

      const isUserRegistered = await axios.get(
        `/api/user/is-verified?username=${countryCode + "-" + nationalNumber}`
      );

      if (isUserRegistered?.data?.success) {
        // send otp password and go to login page
        pageSuffix = "Login";
        page = pagePrefix + pageSuffix;
      }

      setState({ ...state, page });

      if (page === "phoneLogin") {
        resendOTP();
      }

      setMSG("");

      if (page === "phoneRegister") {
        const password = randomstring.generate();

        await axios.put("/api/customer/sign-up-otp", {
          countryCode,
          phoneNumber: `${countryCode + "-" + nationalNumber}`,
        });

        await axios.post("/api/customer/send-sms", {
          username: `${countryCode + "-" + nationalNumber}`,
        });
        inputRef.current?.focus();
      }
    } catch (error: any) {
      setMSG(languages[language.language.short].offline);
    }
  };

  const otpLogin = async (e?: any) => {
    if (e) e.preventDefault();
    try {
      if (!state || !state.username || !state.username.trim()) {
        return setMSG(languages[language.language.short].loginPage.s11);
      }

      const {
        country,
        countryCallingCode: countryCode,
        nationalNumber,
      } = parsePhoneNumber(state.username) as {
        country: string;
        countryCallingCode: string;
        nationalNumber: string;
      };

      if (!country) {
        return setMSG("کد کشور صحیح نیست");
      }

      if (state.page === "phoneRegister") {
        const res = await axios.post("/api/customer/phone-verify", {
          verificationCode: state.otpPassword,
          username: `${countryCode + "-" + nationalNumber}`,
        });
      } else {
        const res = await axios.post("/api/login/otp", {
          username: `${countryCode + "-" + nationalNumber}`,
          verificationCode: state.otpPassword,
          rememberMe: state.rememberMe,
        });
      }
      iClearInterval();
      navigate("/home");
    } catch (error) {
      setMSG(languages[language.language.short].loginPage.s12);
      console.log(error);
    }
  };

  const [captcha, setCaptcha] = useState<string>("");
  const [captchaId, setCaptchaId] = useState<string>("");
  const [captchaCode, setCaptchaCode] = useState<string>("");

  const regenerateCaptcha = async () => {
    try {
      const res: any = await axios
        .get("/api/captcha")
        .catch((error: any) => {});
      setCaptcha("data:image/jpeg;base64," + res.data.captcha);
      setCaptchaId(res.data.id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    regenerateCaptcha();
  }, []);

  const [orgState, setOrgState] = useState<any>({
    username: "",
    password: "",
    rememberMe: null,
  });

  const login = async (e?: any) => {
    if (e) e.preventDefault();

    setMSG(messages.wait);
    if (msg === messages.wait) return;

    try {
      const res: any = await axios.post("/api/login", {
        password: state.password,
        username: state.username,
        rememberMe: state.rememberMe,
      });
      iClearInterval();
      navigate("/home");
    } catch (error: any) {
      try {
        if (!error) setMSG(languages[language.language.short].offline);

        const { data } = error.response;
        switch (data) {
          case "Captcha validation failed":
            setMSG(messages.captchaFail);
            break;
          case "Wrong username or password":
            setMSG(messages.wrongPassword);
            break;
          case "LOGIN SUCCESSFUL":
            break;
          default:
            setMSG(messages.fail);
        }
      } catch (err) {
        console.log(err);
      }
    }

    regenerateCaptcha();
  };

  useEffect(() => {
    (async () => {
      try {
        const myRes: any = await axios.get("/api/current/user");

        if (typeof myRes !== "string") {
          return navigate("/home");
        }

        const res: any = await axios.get("/api/captcha").catch((error: any) => {
          console.log(error);
        });
        setCaptcha("data:image/jpeg;base64," + res.data.captcha);
        setCaptchaId(res.data.id);
      } catch (error) {}
    })();
  }, []);

  const [countDown, setCountDown] = useState(240);

  const startCountDown = async () => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        } as any)
        .then((otp: any) => {
          setState((prevState: any) => {
            return { ...prevState, otpPassword: otp.code };
          });
          ac.abort();
        })
        .catch((err) => {
          ac.abort();
          console.log(err);
        });
    }

    interval = setInterval(function () {
      if (countDown === 0) {
        return iClearInterval();
      }

      setCountDown((currentCountdown) => currentCountdown - 1);
    }, 1000);
  };

  const iClearInterval = () => {
    clearInterval(interval);
    interval = null;
  };

  useEffect(() => {
    if (countDown <= 0) {
      iClearInterval();
    }
  }, [countDown]);
  const dispatch = useDispatch();
  const openLanguagesBox = (e: any) => {
    e.stopPropagation();
    // e.currentTarget.nextElementSibling.classList.toggle("hidden");
    dispatch(boxStatus("open"));
  };

  const changeLanguageFunc = (e: any, lng: any) => {
    // e.currentTarget.parentElement.classList.toggle("hidden");
    dispatch(boxStatus("close"));
    dispatch(
      changeLanguage({
        short: lng,
        rtl: !!languages[lng].rtl,
      })
    );
  };
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setState({ ...state, username: phone });
  }, [phone]);

  const countries = defaultCountries.filter((country: any) => {
    const { iso2 } = parseCountry(country);
    if (config.international) {
      return !["il"].includes(iso2);
    }
    return ["ir"].includes(iso2);
  });

  useEffect(() => {
    if (state.otpPassword.length >= 6) otpLogin();
  }, [state.otpPassword]);

  const organizationPanel = () => {
    setState({ ...state, page: "emailLogin" });
  };

  const loginOrganization = async (e?: any) => {
    if (e) e.preventDefault();

    setMSG(messages.wait);
    if (msg === messages.wait) return;

    const config = {
      headers: {
        "captcha-id": captchaId,
        "captcha-response": captchaCode,
        captchaheadername: "",
      },
    };

    try {
      const res: any = await axios.post(
        "/api/login",
        {
          password: orgState.password,
          username: orgState.username,
          rememberMe: orgState.rememberMe,
        },
        config
      );

      iClearInterval();
      navigate("/home");
    } catch (error: any) {
      try {
        //Captcha response not found

        const { data } = error.response;
        switch (data) {
          case "Captcha validation failed":
            setMSG(messages.captchaFail);
            break;
          case "Wrong username or password":
            setMSG(messages.wrongPassword);
            break;
          case "LOGIN SUCCESSFUL":
            break;
          default:
            setMSG(messages.fail);
        }
      } catch (err) {}
    }

    regenerateCaptcha();
  };

  useEffect(() => {
    setMSG(translator(msg, language.language.short));
  }, [language]);

  const onPasteRef = useRef<PhoneInputRefType>(null);

  let [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const inputElement = onPasteRef.current;
    if (inputElement) {
      const handlePaste = (event: ClipboardEvent) => {
        let pastedText = event.clipboardData?.getData("text") || "";
        pastedText = transform
          .persianNumberToEng(pastedText)
          .replace(/[^0-9]/g, "");
        pastedText = pastedText ? pastedText : "";

        setPhone(`+${countryCode} `);
        setTimeout(() => {
          setPhone(`+${countryCode} ${pastedText}`);
        }, 100);
      };
      inputElement.addEventListener("paste", handlePaste);
      return () => {
        inputElement.removeEventListener("paste", handlePaste);
      };
    }
  }, [countryCode]);

  const setThisPhone = (phone: any, meta: any) => {
    setPhone(phone);

    setCountryCode(meta.country.dialCode);
  };

  const otpLoginNewForm = async () => {
    try {
      if (!state || !state.username || !state.username.trim()) {
        return setMSG(languages[language.language.short].loginPage.s11);
      }

      const {
        country,
        countryCallingCode: countryCode,
        nationalNumber,
      } = parsePhoneNumber(state.username) as {
        country: string;
        countryCallingCode: string;
        nationalNumber: string;
      };

      if (!country) {
        return setMSG("کد کشور صحیح نیست");
      }

      if (state.page === "phoneRegister") {
        const res = await axios.post("/api/customer/phone-verify", {
          verificationCode: state.otpPassword,
          username: `${countryCode + "-" + nationalNumber}`,
        });
      } else {
        const res = await axios.post("/api/login/otp", {
          username: `${countryCode + "-" + nationalNumber}`,
          verificationCode: state.otpPassword,
          rememberMe: state.rememberMe,
        });
      }
      iClearInterval();
      navigate("/home");
    } catch (error) {
      setMSG(languages[language.language.short].loginPage.s12);
      console.log(error);
    }
  };

  const afterEnd = () => {
    setVisibleCountDown(false);
  };

  const resendOTP = async () => {
    const {
      country,
      countryCallingCode: countryCode,
      nationalNumber,
    } = parsePhoneNumber(state.username) as {
      country: string;
      countryCallingCode: string;
      nationalNumber: string;
    };

    const response = await axios.get(
      `/api/user/time-to-resend-code?username=${
        countryCode + "-" + nationalNumber
      }`
    );

    if (response.data.data) {
      setVisibleCountDown(true);
      return setOTPResendTime(response.data.data);
    }

    setVisibleCountDown(true);
    setOTPResendTime(240);

    axios
      .post("/api/forget-password", {
        username: `${countryCode + "-" + nationalNumber}`,
      })
      .catch(() => {
        setState({ ...state, page: "isRegistered" });
        setMSG(languages[language.language.short].loginPage.s12);
      });
    // if (res.data.status === "success") {
    //   setVisibleCountDown(true);
    //   return setOTPResendTime(res.data.otpResendTime);
    // }

    // res.status is fail
  };

  const [haftSin, setHaftSin] = useState(0);

  return (
    <div className={language.language.rtl ? "rtl-direction w-full" : "w-full"}>
      <div
        className={
          state.page === "isRegistered"
            ? "flex justify-center center items-center fixed inset-y-0 inset-x-0 "
            : "hidden"
        }
      >
        <div className="max-w-md rlt-dir w-11/12 mx-auto  md:border md:border-gray-200 p-6 md:rounded-lg">
          <form onSubmit={checkIsUserRegisterd}>
            {" "}
            <img
              alt="هفت سین haft-sin"
              src={haftSinEgg}
              className={haftSin >= 10 ? "block" : "hidden"}
            />
            <div className="text-center w-full  p-2 relative">
              <img
                src={`/pwa/${config.domainName}/shared/logo.png`}
                alt={config.siteTitle}
                className="mx-auto mt-5 max-w-44"
                onClick={() => setHaftSin(haftSin + 1)}
              />
            </div>
            <div className="mb-6 ">
              <div className="w-full flex justify-between">
                <h2 className="mb-6 mt-3 text-2xl text-gray-700">
                  {languages[language.language.short].loginPage.s1}
                </h2>
                <div className="flex text-2xl  items-center text-gray-700 relative">
                  <FaLanguage
                    className="m-1 cursor-pointer"
                    onClick={openLanguagesBox}
                  />{" "}
                  <div
                    className={
                      language.languageBox === "open" ? "block" : "hidden"
                    }
                  >
                    <div
                      className={
                        language.language.rtl
                          ? "absolute bg-gray-600 top-6 left-9 text-lg text-center flex-col p-2 rounded-lg flex"
                          : "absolute bg-gray-600 top-6 right-9 text-lg text-center  flex-col p-2 rounded-lg flex"
                      }
                    >
                      {Object.values(languages).map((lng: any) => (
                        <span
                          className={
                            languages[language.language.short].short ===
                            lng["short"]
                              ? "block cursor-pointer hover:text-white  text-teal-400"
                              : "block cursor-pointer text-gray-200 hover:text-white"
                          }
                          onClick={(e) => changeLanguageFunc(e, lng["short"])}
                        >
                          {lng["language"]}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <label
                htmlFor="success"
                className="block mb-4 text-sm font-medium text-gray-500 mt-4"
              >
                {languages[language.language.short].loginUserNameTitle}
              </label>

              <PhoneInput2
                defaultCountry={config.defaultCoutnry}
                ref={onPasteRef}
                autoFocus={true}
                hideDropdown={countries.length === 1 ? true : false}
                forceDialCode={countries.length === 1 ? true : false}
                value={phone}
                countries={countries}
                onChange={setThisPhone}
              />

              <div className="flex items-center gap-2 text-gray-700 mt-2">
                <span
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() =>
                    setState({ ...state, rememberMe: !state.rememberMe })
                  }
                >
                  {languages[language.language.short].loginPage.s15}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 50 50"
                    className={
                      state.rememberMe ? "fill-black cursor-pointer" : "hidden"
                    }
                  >
                    <path d="M 11 4 C 7.101563 4 4 7.101563 4 11 L 4 39 C 4 42.898438 7.101563 46 11 46 L 39 46 C 42.898438 46 46 42.898438 46 39 L 46 15 L 44 17.3125 L 44 39 C 44 41.800781 41.800781 44 39 44 L 11 44 C 8.199219 44 6 41.800781 6 39 L 6 11 C 6 8.199219 8.199219 6 11 6 L 37.40625 6 L 39 4 Z M 43.25 7.75 L 23.90625 30.5625 L 15.78125 22.96875 L 14.40625 24.4375 L 23.3125 32.71875 L 24.09375 33.4375 L 24.75 32.65625 L 44.75 9.03125 Z"></path>
                  </svg>
                  <FaRegSquare
                    className={
                      !state.rememberMe
                        ? "text-xl text-gray-400  cursor-pointer"
                        : "hidden"
                    }
                  />
                </span>
              </div>

              <p className="text-sm text-red-500  mt-2 mb-4">
                <span className="font-medium">{msg}</span>
              </p>
              <div className="flex mt-1  flex-col">
                <button
                  type="submit"
                  className="text-white bg-teal-500 border hover:bg-teal-600 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full"
                >
                  {languages[language.language.short].continue}
                </button>
                <p className="text-sm text-center mt-2">
                  {config.domainName !== "yee" ? (
                    <Link to="/page/privacy">
                      {languages[language.language.short].loginPage.s2}
                    </Link>
                  ) : (
                    languages[language.language.short].loginPage.s2
                  )}

                  {languages[language.language.short].loginPage.s4}
                </p>
              </div>
              <div
                className="text-center mt-6 text-teal-600 cursor-pointer"
                onClick={organizationPanel}
              >
                <span>{languages[language.language.short].loginPage.s16}</span>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        className={
          state.page === "phoneLogin" || state.page === "phoneRegister"
            ? "flex justify-center center items-center fixed inset-y-0 inset-x-0"
            : "hidden"
        }
      >
        <div className="max-w-md rlt-dir w-11/12 mx-auto  md:border md:border-gray-200 p-6 md:rounded-lg relative">
          <div className="text-center w-full p-2 relative">
            <img
              src={`/pwa/${config.domainName}/shared/logo.png`}
              alt={config.siteTitle}
              className="mx-auto mt-5 max-w-44"
            />
            <span
              className={
                language.language.rtl
                  ? "top-1/2 right-0 absolute cursor-pointer"
                  : "top-1/2 left-0 absolute cursor-pointer"
              }
              onClick={() => {
                setState({
                  ...state,
                  page: "isRegistered",
                  otpPassword: "",
                });
                // resendOTP();
                iClearInterval();
                setMSG("");
                setHaftSin(0);
              }}
            >
              {language.language.rtl ? <FaArrowRight /> : <FaArrowLeft />}
            </span>
          </div>
          <div className="mb-6">
            <h2 className="mb-6 mt-3 text-2xl text-gray-700">
              {languages[language.language.short].loginPage.s7}
            </h2>
            <p className="text-gray-700 text-sm mb-6">
              {languages[language.language.short].loginPage.s8}
              <span className="ltr-direction bidi">
                {languages[language.language.short] === "fa"
                  ? transform.engNumberToPersian(state.username)
                  : state.username}
              </span>{" "}
              {languages[language.language.short].loginPage.s9}
            </p>
            <form onSubmit={otpLogin}>
              <label
                htmlFor="success"
                className="block mb-2 text-sm font-medium text-gray-500"
              >
                {languages[language.language.short].confirmationCode}
              </label>
              {state.page === "phoneLogin" || state.page === "phoneRegister" ? (
                <div className="w-full ltr-direction">
                  <VerificationInput
                    length={6}
                    validChars="۰-۹0-9"
                    placeholder=" "
                    ref={inputRef}
                    onComplete={(value) => {
                      otpLoginNewForm();
                    }}
                    onChange={(value) => {
                      setState({ ...state, otpPassword: value });
                    }}
                    autoFocus={true}
                    value={state.otpPassword}
                    classNames={{
                      container: "gap-1 justify-center mx-auto",
                      character: "border-teal-700  rounded-md",
                      characterFilled: " border-teal-700",
                    }}
                  />
                </div>
              ) : null}

              <p className="text-sm text-center">
                <span className="font-medium">{msg}</span>
              </p>

              <div className="my-8"></div>

              <div className="mt-4 mb-2 text-center">
                {visibleCountDown ? (
                  <CountdownTimer
                    afterEnd={afterEnd}
                    initialMinutes={(otpResendTime - (otpResendTime % 60)) / 60}
                    initialSeconds={otpResendTime % 60}
                  />
                ) : (
                  <span className="cursor-pointer" onClick={resendOTP}>
                    {languages[language.language.short].loginPage.s18}
                  </span>
                )}
              </div>

              <div className="flex mt-1 md:flex-row flex-col">
                <button
                  type="submit"
                  className="text-white border border-teal-500 bg-teal-400 hover:bg-teal-600 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2 w-full"
                >
                  {languages[language.language.short].signin}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={
          state.page === "emailLogin"
            ? "flex justify-center center items-center fixed inset-y-0 inset-x-0   "
            : "hidden"
        }
      >
        <div className="max-w-md rlt-dir w-11/12 mx-auto md:shadow-xl shadow-gray-300  md:rounded-lg p-4">
          <div className="text-center w-full  p-2 relative">
            <img
              src={`/pwa/${config.domainName}/shared/logo.png`}
              alt="logo"
              className="mx-auto mt-5 max-w-44"
            />
            <span
              className="top-1/2 right-0 absolute cursor-pointer"
              onClick={() => {
                setState({
                  ...state,
                  page: "isRegistered",
                  otpPassword: "",
                });
                iClearInterval();
                setCountDown(119);
                setMSG("");
                setHaftSin(0);
              }}
            >
              <FaArrowRight />
            </span>
          </div>
          <div className="mb-6">
            <form onSubmit={loginOrganization}>
              <label
                htmlFor="success"
                className="block mb-2 text-sm font-medium text-gray-500 mt-8"
              >
                {languages[language.language.short].username}
              </label>
              <input
                type="text"
                id="success2"
                className="bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                placeholder={languages[language.language.short].insertUserName}
                onChange={(e) =>
                  setOrgState({ ...orgState, username: e.target.value })
                }
                value={orgState.email}
              />
              <label
                htmlFor="success"
                className="block mb-2 text-sm font-medium text-gray-500 mt-8"
              >
                {languages[language.language.short].loginPasswordTitle}
              </label>
              <input
                type="password"
                id="success2"
                className="bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                placeholder={
                  languages[language.language.short].loginPasswordPlaceHolder
                }
                onChange={(e) =>
                  setOrgState({ ...orgState, password: e.target.value })
                }
                value={orgState.password}
              />
              <div className="w-full flex items-center text-lg mt-8">
                <img src={captcha} alt="captcha" />
                <FaArrowRotateRight
                  className="mr-1 cursor-pointer"
                  onClick={regenerateCaptcha}
                />
              </div>
              <label
                htmlFor="success"
                className="block mb-2 text-sm font-medium text-gray-500 mt-4"
              >
                {languages[language.language.short].captcha}
              </label>
              <input
                type="text"
                id="success3"
                className="bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                placeholder={languages[language.language.short].captcha}
                onChange={(e) => setCaptchaCode(e.target.value)}
                value={captchaCode}
              />
              <div className="my-8"></div>
              <p className="text-sm text-red-500 ">
                <span className="font-medium">{msg}</span>
              </p>
              <div className="flex mt-1 md:flex-row flex-col">
                <button
                  type="submit"
                  className="border  hover:bg-gray-100 hover:text-teal-500 hover:border-teal-500 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-white bg-teal-500    w-full"
                >
                  {languages[language.language.short].signin}
                </button>

                {/* <button
                  onClick={() => {
                    setState({ ...state, page: "isRegistered" });
                    iClearInterval();
                    setCountDown(120);
                    setMSG("");
                  }}
                  type="button"
                  className="text-teal-500 bg-white border border-teal-500  hover:bg-teal-500 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full"
                >
                  {languages[language.language.short].correct}
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
