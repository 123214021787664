import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import languages from "./../languages/languages";
import { transform } from "../utils/persianTransform";
interface CountdownTimerProps {
  initialMinutes: number;
  initialSeconds: number;
  afterEnd: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  initialMinutes,
  initialSeconds,
  afterEnd,
}) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const language = useSelector((state: any) => state.language);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) return afterEnd(); // Stop the timer at 0:00

    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else if (minutes > 0 && seconds === 0) {
        setMinutes((prevMinutes) => prevMinutes - 1);
        setSeconds(59);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [minutes, seconds]);

  const renderToCurrentLanguage = () => {
    return languages[language.language.short].rtl
      ? `${transform.engNumberToPersian(
          String(minutes).padStart(2, "0")
        )}:${transform.engNumberToPersian(String(seconds).padStart(2, "0"))}`
      : `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0"
        )}`;
  };
  return (
    <span>
      {renderToCurrentLanguage()}{" "}
      {languages[language.language.short].loginPage.s10}
    </span>
  );
};

export default CountdownTimer;
